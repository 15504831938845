var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "名称",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["itemText", _vm.validatorRules.itemText],
                        expression: "['itemText', validatorRules.itemText]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: "请输入名称", autocomplete: "off" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "数据值",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["itemValue", _vm.validatorRules.itemValue],
                        expression: "['itemValue', validatorRules.itemValue]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: "请输入数据值", autocomplete: "off" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "描述",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["description"],
                        expression: "['description']",
                      },
                    ],
                    attrs: { autocomplete: "off" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "排序值",
                  },
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["sortOrder", { initialValue: 1 }],
                        expression: "['sortOrder', { initialValue: 1 }]",
                      },
                    ],
                    attrs: { min: 1 },
                  }),
                  _vm._v("\n        值越小越靠前，支持小数\n      "),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "是否启用",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-switch", {
                    attrs: {
                      checkedChildren: "启用",
                      unCheckedChildren: "禁用",
                    },
                    on: { change: _vm.onChose },
                    model: {
                      value: _vm.visibleCheck,
                      callback: function ($$v) {
                        _vm.visibleCheck = $$v
                      },
                      expression: "visibleCheck",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }